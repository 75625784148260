export default {
    data() {
        return {
            tableFilters: {
                createdAt: null,
                lastModifiedAt: null,
                clientSignDate: null,
                companySignDate: null,
                startDate: null,
                endDate: null,
                expireRemindDate: null,
                clientId: null,
                companySignatoryName: "",
                clientSignatoryName: "",
            }
        }
    }
}